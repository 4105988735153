import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Hero from '../components/Hero'
import { ThreeGrid, TwoGrid } from '../components/Grid'
import { RightTriangleBorder, LeftTriangleBorder } from '../components/Triangle'
import Card from '../components/Card'
import ApiCard from '../components/ApiCard'
import CtaSection from '../components/CtaSection'
import NonStretchedImage from '../components/NonStretchedImage'
import DeveloperTechSection from '../sections/DeveloperTechSection'

export const IndexPageTemplate = ({
  hero,
  features,
  apiLinkSection,
  developerTechSection,
  integrateApiSection,
  privacyCoreSection,
}) => (
  <div>
    <Hero heroData={hero}/>
    <section className="section is-black">
      <div className="container">
        <h2 className="section-title is-white-text has-text-centered">
          {features.title}
        </h2>
        <p className="section-description is-white-text has-text-centered">
          {features.description}
        </p>
        <ThreeGrid>
          {features.list.map(item=>{
            return(
              <Card cardData={item} />
            )
          })}
        </ThreeGrid>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <TwoGrid>
          <div>
            <NonStretchedImage
              objectFit="contain"
              alt={apiLinkSection.title}
              className=""
              {...apiLinkSection.image}
            />
          </div>
          <div>
            <h2 className="section-title is-primary-text">
              {apiLinkSection.title}
            </h2>
            <p className="section-description">
              {apiLinkSection.description}
            </p>
            {apiLinkSection.linkNewWindow?(
              <a href={apiLinkSection.buttonLink} target="_blank" rel="noreferrer">
                <button className="is-primary">
                  {apiLinkSection.buttonTxt}
                </button>
              </a>
            ):(
              <Link to={apiLinkSection.buttonLink}>
                <button className="is-primary">
                  {apiLinkSection.buttonTxt}
                </button>
              </Link>
            )}
              
          </div>
        </TwoGrid>
      </div>
    </section>
    <DeveloperTechSection sectionData={developerTechSection} />
    <RightTriangleBorder />
    <section className="section">
      <div className="container">
        <h2 className="section-title is-primary-text has-text-centered">
          {integrateApiSection.title}
        </h2>
        <ThreeGrid>
          {integrateApiSection.list.map(item=>{
            return(
              <ApiCard cardData={item} />
            )
          })}
        </ThreeGrid>
      </div>
    </section>
    <section className="section  is-gray-bg">
      <div className="container">
        <TwoGrid>
          <div>
            <NonStretchedImage
              objectFit="contain"
              alt={privacyCoreSection.title}
              className=""
              {...privacyCoreSection.image}
            />
          </div>
          <div>
            <h2 className="section-title is-primary-text">
              {privacyCoreSection.title}
            </h2>
            <p className="section-description">
              {privacyCoreSection.description}
            </p>
            {privacyCoreSection.linkNewWindow?(
              <a href={privacyCoreSection.buttonLink} target="_blank" rel="noreferrer">
                <button className="is-primary">
                  {privacyCoreSection.buttonTxt}
                </button>
              </a>
            ):(
              <Link to={privacyCoreSection.buttonLink}>
                <button className="is-primary">
                  {privacyCoreSection.buttonTxt}
                </button>
              </Link>
            )}
              
          </div>
        </TwoGrid>
      </div>
    </section>
    <LeftTriangleBorder />
    <CtaSection bgClassName="is-white" />
  </div>
)

IndexPageTemplate.propTypes = {
  hero: PropTypes.object,
  features: PropTypes.object,
  apiLinkSection: PropTypes.object,
  developerTechSection: PropTypes.object,
  integrateApiSection: PropTypes.object,
  privacyCoreSection: PropTypes.object,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <SEO 
        title={frontmatter.seoTitle}
        description={frontmatter.seoDescription}
      />
      <IndexPageTemplate
        hero={frontmatter.hero}
        features={frontmatter.features}
        apiLinkSection={frontmatter.apiLinkSection}
        developerTechSection={frontmatter.developerTechSection}
        integrateApiSection={frontmatter.integrateApiSection}
        privacyCoreSection={frontmatter.privacyCoreSection}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        seoTitle
        seoDescription
        hero {
          title
          subtitle
          description
          image {
            publicURL
            extension
            childImageSharp {
              fluid(maxHeight: 534, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
                presentationWidth
              }
            }
          }
          buttons {
            text
            link
            bg
            linkNewWindow
          }
        }
        features {
          title
          description
          list {
            image {
              publicURL
              extension
              childImageSharp {
                fluid(maxHeight: 44, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                  presentationWidth
                }
              }
            }
            item
            description
          }
        }
        apiLinkSection {
          title
          description
          image {
            publicURL
            extension
            childImageSharp {
              fluid(maxHeight: 399, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
                presentationWidth
              }
            }
          }
          buttonTxt
          buttonLink
          linkNewWindow
        }
        developerTechSection {
          title
          image {
            publicURL
            extension
            childImageSharp {
              fluid(maxHeight: 433, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
                presentationWidth
              }
            }
          }
          faceMatch {
            label
            title
            description
            buttonTxt
            buttonLink
            linkNewWindow
          }
          faceSearch {
            label
            title
            description
            buttonTxt
            buttonLink
            linkNewWindow
          }
        }
        integrateApiSection {
          title
          list {
            item
            description
            link
            image {
              publicURL
              extension
              childImageSharp {
                fluid(maxHeight: 37, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                  presentationWidth
                }
              }
            }
          }
        }
        privacyCoreSection {
          title
          description
          buttonTxt
          buttonLink
          linkNewWindow
          image {
            publicURL
            extension
            childImageSharp {
              fluid(maxHeight: 399, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
                presentationWidth
              }
            }
          }
        }
      }
    }
  }
`
