import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import NonStretchedImage from '../components/NonStretchedImage'

export const ContentGrid = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;

  max-width: 1120px;
  width: 100%;
  margin: 50px auto 0;
  & label {
  	color: #6F8D9E;
  	font-weight: 700;
  }
  & h3 {
  	margin-top: 11px;
  	font-size: 24px;
  }
  & p {
  	height: 130px;
  	margin: 20px 0 40px;
  	font-size: 18px;
  	line-height: 32px;
  	overflow: hidden;
  	text-overflow: ellipsis;
  	display: -webkit-box;
  	-webkit-line-clamp: 4;
  	-webkit-box-orient: vertical
  }
  @media(max-width: 768px) {
  	grid-template-columns: repeat(1, 1fr);
  	grid-row-gap: 40px;
  	& div {
  		text-align: center;
  	}
  	& div:nth-child(3) {
  		order: -1;
  	}
  	& h3 {
  		text-align: center;
  	}
  	& p {
	  	height: auto;
	  	margin: 20px 0 30px;
	  	font-size: 18px;
	  	line-height: 32px;
	  	overflow: show;
	  	text-align: center;
	  }
	  & button {
	  	width: 100%;
	  	max-width: 400px;
	  	margin: auto;
	  }
  }
`

const DeveloperTechSection = ({sectionData}) => (
	<section className="section is-gray-bg">
		<div className="container">
			<h2 className="section-title is-primary-text has-text-centered">
        {sectionData.title}
      </h2>
			<ContentGrid>
				<div>
					<label>{sectionData.faceMatch.label}</label>
					<h3>{sectionData.faceMatch.title}</h3>
					<p>{sectionData.faceMatch.description}</p>
					{sectionData.faceMatch.linkNewWindow?(
						<a href={sectionData.faceMatch.buttonLink} target="_blank" rel="noreferrer">
							<button className="is-primary">{sectionData.faceMatch.buttonTxt}</button>
						</a>
					):(
						<Link to={sectionData.faceMatch.buttonLink}>
							<button className="is-primary">{sectionData.faceMatch.buttonTxt}</button>
						</Link>
					)}
					
				</div>
				<div>
					<label>{sectionData.faceSearch.label}</label>
					<h3>{sectionData.faceSearch.title}</h3>
					<p>{sectionData.faceSearch.description}</p>
					{sectionData.faceSearch.linkNewWindow?(
						<a href={sectionData.faceSearch.buttonLink} target="_blank" rel="noreferrer">
							<button className="is-primary">{sectionData.faceSearch.buttonTxt}</button>
						</a>
					):(
						<Link to={sectionData.faceSearch.buttonLink}>
							<button className="is-primary">{sectionData.faceSearch.buttonTxt}</button>
						</Link>
					)}
				</div>
				<div>
					<NonStretchedImage
						objectFit="contain"
						alt={sectionData.title}
						className=""
						{...sectionData.image}
		      />
				</div>
			</ContentGrid>
		</div>
	</section>
)

export default DeveloperTechSection
	