import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import NonStretchedImage from './NonStretchedImage'

const HeroWrap = styled.div`
	position: relative;
	width: 100%;
	padding: 100px 0 0px;
	background: #F6F9F9;
	& h1 {
		width: 100%;
		max-width: 800px;
		margin: 0 auto;
		color: #122D3D;
		text-align: center;
	}
	& p {
		width: 100%;
		max-width: 558px;
		margin: 18px auto;
		color: #333333;
		font-size: 18px;
		text-align: center;
	}
	@media(max-width: 480px) {
		padding-top: 80px;
	}
`
const ButtonWrap = styled.div`
	display: flex;
	max-width: 400px;
	margin: 20px auto 37px;
	& a {
		width: 48%;
		&:nth-child(1) {
			margin-right: 3%;
		}
	}
	@media(max-width: 600px) {
		display: block;
	}
`
const Button = styled.button`
	width: 100%;
	background: ${props=>props.bg};
	border: 1px solid ${props=>props.bg};
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: white;
  padding: 12px;
  &:focus {
    outline: none !important;
  }
  &:hover {
  	background: #6F8D9E;
  	border: 1px solid #6F8D9E;
    cursor: pointer;
  }
  @media(max-width: 600px) {
  	margin-bottom: 20px;
  }
`
const ImageWrap = styled.div`
	position: relative;
	z-index: 2;
	.gatsby-image-wrapper {
		width: 100%;
		margin: auto;
	}
	@media(max-width: 768px){
		.gatsby-image-wrapper {
			max-width: 500px;
			width: 100%;
			margin: auto;
		}
	}
`
const TriangleBorder = styled.div`
	position: relative;
	z-index: 1;
	border-top: 250px solid #F6F9F9;
	border-right: 100vw solid white;
	margin-top: -400px;
	@media(max-width: 768px) {
		border-top: 150px solid #F6F9F9;
		border-right: 100vw solid white;
		margin-top: -200px;
	}
`

const SubTitle = styled.h3`
	margin-bottom: -40px;
	padding: 10px 0 0px;;
	font-size: 24px;
	text-align: center;
	@media(max-width: 600px) {
  	margin-bottom: 20px;
  }
`

class Hero extends React.Component {
	render() {
		const { heroData } = this.props
		return(
			<HeroWrap>
				<section className="section">
					<div className="container">
						<h1>{heroData.title}</h1>
						<p>{heroData.description}</p>
						<ButtonWrap>
							{
								heroData.buttons.map(item=>{
									if (item.linkNewWindow) {
										return(
											<a href={item.link} target="_blank" rel="noreferrer">
												<Button bg={item.bg}>
													{item.text}
												</Button>
											</a>
										)
									} 
									return (
										<Link to={item.link}>
											<Button bg={item.bg}>
												{item.text}
											</Button>
										</Link>
									)
								})
							}
						</ButtonWrap>
						<ImageWrap>
							<div>
								<NonStretchedImage
									objectFit="contain"
									alt="hero-image"
									className=""
									{...heroData.image}
					      />
							</div>
						</ImageWrap>
					</div>
				</section>
				<TriangleBorder />
				<section className="section is-white">
					<div className="container">
						<SubTitle>{heroData.subtitle}</SubTitle>
					</div>
				</section>	
			</HeroWrap>
		)
	}
}

export default Hero